import React from 'react'
import '../about/Experience.css'
import { Slide, Zoom } from "react-awesome-reveal";

export const ExperienceData = [
  {
    id: 1,
    // designation: "",
    designation: (
      <>
        The Founder / CEO of  <span class='green'>ZAI SYSTEMS (SMC-Private) Limited </span>
      </>
    ),
    tenure: " Jan 2015 – Present ",
    statement: (
      <>
        Zai Systems (SMC-Private) Limited is the most trusted technology solution expert, providing IT Services and Solutions for businesses to grow. Develop high-quality business strategies and plans ensuring their alignment with short-term and long-term objectives.

      </>
    )

  },
  {
    id: 2,
    designation: (
      <>
        Technical Project Manager at <span class='green'>Soloinsight -Cloud Gate Platform </span>
      </>
    ),
    tenure: "Feb 2023-Present",
    statement: (
      <>

      </>
    )
  },
  {
    id: 3,
    designation: (
      <>
        Product Manager / Scrum Master at <span class='green'>Rodeo Logistics </span>
      </>
    ),
    tenure: "May 2022 - Jan 2023",
    statement: (
      <>

      </>
    )
  },
  {
    id: 4,
    designation: (
      <>
        Senior Tech Project Manager Team Lead at  <span class='green'>SIMPLE LOGIX
        </span>
      </>
    ),
    tenure: "Oct 2020 – April 2022",
    statement: (
      <>

      </>
    )
  },
  {
    id: 5,
    designation: (
      <>
        Full Stack Developer /Team lead at  <span class='green'>ALCHEMATIVE
        </span>
      </>
    ),
    tenure: "Jan 2020 – Sep 2020",
    statement: (
      <>

      </>
    )
  },
  {
    id: 6,
    designation: (
      <>
        Full Stack Sr. Microsoft .net Core & MVC5 developer at   <span class='green'>SPORTECH
        </span>
      </>
    ),
    tenure: "Jan 2018 – Nov 2019",
    statement: (
      <>

      </>
    )
  },
  {
    id: 7,
    designation: (
      <>
        Full Stack Web Developer at  <span class='green'>IPSUM SOLUTIONS
        </span>
      </>
    ),
    tenure: "June 2014 – Nov 2017",
    statement: (
      <>

      </>
    )
  },
]

const Experience = () => {
  const cursorStyle = {
    cursor: 'pointer'
  };
  return (

    <div className="container experience" >
      <div className="row">
        <div class="col-md-12 text-center" >
          <Slide direction="down">
            <h1>Professional  <span className="green">Journey</span></h1>
          </Slide>
        </div>
        
        <div class="col-md-12 exp">
          <Zoom>
            <h4>Work<span class="green"> History</span></h4>
          </Zoom>
          {ExperienceData.map((experience, index) => (
            <div key={index}>
              <div className="exp-flex">
                <p className="exp-index">{experience.id}</p>
                <p className="exp-tenure">{experience.tenure}</p>
              </div>
              <div className="exp-line">
                <div className="exp-details">
                  <h5>{experience.designation}</h5>
                  {/* <p>{experience.statement}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>


      </div>
    </div>
    )
}

export default Experience

{/* <div class="col-md-12 " >
          <h4>Work<span className="green"> History</span></h4>
          {ExperienceData.map((experience, index) => (
            <div key={index}>
              <span className='exp-flex'>
                <p className='exp-index'>{experience.id}</p>
                <p className='exp-tenure'>{experience.tenure}</p>
              </span>
             
                <h5>{experience.designation}</h5>
                <p>{experience.statement}</p>
              


            </div>
          ))}

        </div> */}