import React from 'react'
import Clients from './Clients'

const Textimonials = () => {
  return (
    <>
    <div className='testimonials'>
        <Clients/>
       
    </div>
    {/* <div className="skill_shadow_bottom">
            </div> */}
            </>
  )
}

export default Textimonials