import React from "react";
import styled from "styled-components";
import { FaLinkedinIn } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";
import { ReactTyped } from 'react-typed';
import image from '../images/Main pic.webp';
// import linkedin from '../images/linkedin.svg'
import '../Banner/ProfComponent.css'
import { useMediaQuery } from '@mui/material';

import { Fab, Grid, Stack, Typography } from "@mui/material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import SouthIcon from '@mui/icons-material/South';
const ProfComponent = () => {
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  return (
    // <Container id="home">
    //   <Slide direction="left">
    //     <Texts>
    //       <h1>
    //         <span>Hi, This is Owais Ahmad Khan</span>
    //       </h1>
    //       <h2 >
    //         <ReactTyped
    //           showCursor={false}
    //           style={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //             color: '#01be96',
    //             fontSize: '22px',
    //             height: '40px'
    //           }}
    //           className='theme-gradient'
    //           strings={[  'Tech Entrepreneur | IT Consultant ',
    //           'Strategic Thinker | Technology Enthusiast',
    //           ' Technical Project Manager | Scrum Master ',
    //           'Program Manager | Agile Product Manager',
    //           ' Product Owner | Transformational Coach']}
    //           typeSpeed={80}
    //           backSpeed={5}
    //           backDelay={1000}
    //           loop
    //         />
    //       </h2>
    //       <h3>
    //         Founder and CEO of Zai Systems
    //       </h3>
    //       <Social>
    //         <p>Check out my</p>
    //         <div className="social-icons">
    //           <span>
    //             <a href="https://www.instagram.com/owais.ahmad60?igsh=MTNodGhuMndibnI5NA==" target="_blank">
    //               <FaLinkedinIn />
    //               {/* <img src={linkedin} alt="linkedin" /> */}
    //             </a>
    //           </span>
    //         </div>
    //       </Social>
    //     </Texts>
    //   </Slide>
    //   <Slide direction="right">
    //     <Profile>
    //       <img
    //         src={image}
    //         alt="profile"
    //       />
    //     </Profile>
    //   </Slide>
    // </Container>
    //   <div className="profmain">
    //   <div className="container ">
    //   <div className="row profComponent">
    //     <div className=" col-md-7 Texts">
    //       <h1>
    //         <span>Hi, This is Owais Ahmad Khan</span>
    //       </h1>
    //       <h2>
    //               <ReactTyped
    //             showCursor={false}
    //             style={{
    //               display: 'flex',
    //               flexDirection: 'row',
    //               color: '#01be96',
    //               fontSize: '22px',
    //               height: '37px',

    //             }}
    //             className='theme-gradient'
    //             strings={[  'Tech Entrepreneur | IT Consultant ',
    //             'Strategic Thinker | Technology Enthusiast',
    //             ' Technical Project Manager | Scrum Master ',
    //             'Program Manager | Agile Product Manager',
    //             ' Product Owner | Transformational Coach']}
    //             typeSpeed={80}
    //             backSpeed={5}
    //             backDelay={1000}
    //             loop
    //           /> 
    //       </h2>
    //       <h3>Founder and CEO of Zai Systems</h3>
    //       <div className="Social">
    //         <p>Check out my</p>
    //         <div className="social-icons">
    //           <span>
    //             <a href="https://www.instagram.com/owais.ahmad60?igsh=MTNodGhuMndibnI5NA==" target="_blank">
    //             <FaLinkedinIn />
    //             </a>
    //           </span>
    //         </div>
    //       </div>
    //     </div>



    //       <div className="Profile col-md-5">
    //     <Slide direction="right">   <img src={image} alt="profile" /> </Slide>
    //     </div>

    //   </div>
    // </div>
    // </div>

    // <div className='slider-area slider-style-6 shape-left inheader-not-transparent height-500'>
    <div className=''>
      {/* <div className='row row--30 align-items-center'>
          <div className=' col-lg-6 mt_md--50 mt_sm--50 mt_lg--30'> */}
      <div className='inner text-start'>
        <Grid container spacing={3} marginTop={isMobileOrTablet ? '20%' : ''}>
          <Grid item xs={isMobileOrTablet? 2 : 2} md={2} lg={1}>
            <div className='vector1'>
              <Stack direction={'column'} spacing={2}>
                <Fab size="small" variant="outlined" color="secondary" aria-label="add"
                  sx={{
                    borderRadius: '50%',
                    border: '2px solid',
                    borderColor: '#FCFCFC',
                    bgcolor: 'transparent',
                    color: '#FCFCFC',
                    width: isMobileOrTablet ? '30px' : '40px',
                    height: isMobileOrTablet ? '30px' : '40px', 
                    minHeight: 'unset', 
                    p: isMobileOrTablet ? '5px' : '10px', 
                  }}
                >
                  <EmailOutlinedIcon fontSize="small" sx={{
                    width: isMobileOrTablet ? '15px' : '20px', 
                    height: isMobileOrTablet ? '15px' : '20px',
                  }} />
                </Fab>

                <Fab size="small" variant="outlined" color="secondary" aria-label="add"
                  sx={{
                    borderRadius: '50%',
                    border: '2px solid',
                    borderColor: '#FCFCFC',
                    bgcolor: 'transparent',
                    color: '#FCFCFC',
                    width: isMobileOrTablet ? '30px' : '40px',
                    height: isMobileOrTablet ? '30px' : '40px', 
                    minHeight: 'unset', 
                    p: isMobileOrTablet ? '5px' : '10px', 
                  }}
                >
                  <InstagramIcon fontSize="small" sx={{
                    width: isMobileOrTablet ? '15px' : '20px', 
                    height: isMobileOrTablet ? '15px' : '20px',
                  }} />
                </Fab>
                 
                <Fab size="small" variant="outlined" color="secondary" aria-label="add"
                  sx={{
                    borderRadius: '50%',
                    border: '2px solid',
                    borderColor: '#FCFCFC',
                    bgcolor: 'transparent',
                    color: '#FCFCFC',
                    width: isMobileOrTablet ? '30px' : '40px',
                    height: isMobileOrTablet ? '30px' : '40px', 
                    minHeight: 'unset', 
                    p: isMobileOrTablet ? '5px' : '10px', 
                  }}
                >
                  <LinkedInIcon fontSize="small" sx={{
                    width: isMobileOrTablet ? '15px' : '20px', 
                    height: isMobileOrTablet ? '15px' : '20px',
                  }} />
                </Fab>
                
                  
                <Fab size="small" variant="outlined" color="secondary" aria-label="add"
                  sx={{
                    borderRadius: '50%',
                    border: '2px solid',
                    borderColor: '#FCFCFC',
                    bgcolor: 'transparent',
                    color: '#FCFCFC',
                    width: isMobileOrTablet ? '30px' : '40px',
                    height: isMobileOrTablet ? '30px' : '40px', 
                    minHeight: 'unset', 
                    p: isMobileOrTablet ? '5px' : '10px', 
                  }}
                >
                  <FacebookOutlinedIcon fontSize="small" sx={{
                    width: isMobileOrTablet ? '15px' : '20px', 
                    height: isMobileOrTablet ? '15px' : '20px',
                  }} />
                </Fab>
              </Stack>

            </div>
          </Grid>
          <Grid item xs={isMobileOrTablet? 9 : 10} md={7} lg={8}>

            <Slide direction="left">
              <h1 className='title color-white text-center'>
                This is
              </h1>
              <Typography variant="h1" fontSize={isMobileOrTablet ? '200%' : '6rem'} fontWeight={'bold'} color={'#0DCB81'} textAlign={'center'}>
                Owais Ahmad Khan
              </Typography>
              <Slide direction="right">
                <div className="profileImage">
                  <img src={image} alt='profile' style={{ width: '35%', height: '35%' }} className="imgg" />
                </div>
              </Slide>
            </Slide>
            {/* <Slide direction="right"> */}
            {/* <div className="Hero-tag">
              Tech Entrepreneur | IT Consultant
            </div> */}
            {/* </Slide> */}
          </Grid>
          <Grid item xs={12} md={7} lg={3} sx={{ borderLeft: '1px solid #383C43' }}>
            <Stack
              direction={isMobileOrTablet ? 'row' : 'column'}
              spacing={isMobileOrTablet ? 3 : 8}
              className="hero-third-section"
              maxWidth={'75%'}
            >
              <div className="part1 baseline">
                <Typography variant="h2" fontSize={isMobileOrTablet ? '1rem' : '3rem'} fontWeight="bold" textAlign="center">
                  501+
                </Typography>
                <p className="hero-third-section-lightData">PROJECTS COMPLETED</p>
              </div>
              <hr />
              <div className="baseline">
                <Typography variant="h3" fontSize={isMobileOrTablet ? '1rem' : '2.5rem'} fontWeight="bold">
                  Founder / CEO of Zai Systems
                </Typography>
              </div>
              <hr />
              <div className="baseline part3">
                <button href='#about' className="Hero-downArrow">
                  <div >
                    <SouthIcon />
                  </div>
                </button>
                <p className="hero-third-section-lightData scroll-down">SCROLL DOWN</p>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </div>
    // </div>

  );
};

export default ProfComponent;

// const Container = styled.div`
//   display: flex;
//   gap: 2rem;
//   height:100vh;
//   padding-top: 8rem;
//   width: 80%;
//   max-width: 1280px;
//   margin: 0 auto;
//   z-index: 1;

//   @media (max-width: 840px) {
//     width: 90%;
//   padding-top: 3rem;

//   }

//   @media (max-width: 640px) {
//     flex-direction: column;
//   }
// `;
// const Texts = styled.div`
//   flex: 1;
//   padding-top: 10rem;

//   h1 {
//     font-size: 2.2rem;
//     font-family: "Secular One", sans-serif;
//     text-align:center;
//     letter-spacing: 1px;
//   }
//   h3{
//     font-size: 1.8rem;
//     text-align:center
//   }

//   @media (max-width: 840px) {

//   padding-top: 3rem;
// h1{
//     font-size:1.5rem;
//     text-align:left;
// }

// h3{
//   font-size: 1.5rem;
//   text-align:left;
//   margin-top:19px;
// }
//   }

//   button {
//     padding: 0.7rem 2rem;
//     margin-top: 3rem;
//     cursor: pointer;
//     background-color: #01be96;
//     border: none;
//     color: #fff;
//     font-weight: 500;
//     filter: drop-shadow(0px 10px 10px #01be9551);
//     :hover {
//       filter: drop-shadow(0px 10px 10px #01be9570);
//     }
//   }
// `;
// const Profile = styled.div`
// display: flex;
// justify-content: center; 
// align-items: center;
//   img {
//     width: 330px;
//     height: 467px;


//     transition: transform 400ms ease-in-out;

//   }
//   @media (min-width: 640px) {
//     img {
//       max-width: 330px; /* Limit width to 330px on larger screens */
//     }
//   :hover img {
//     transform: translateY(-10px);
//   }
// `;
const Social = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      margin-bottom: 11px;
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #01be96;
      transition: transform 400ms ease-in-out;
      display: flex; 
      justify-content: center; 
      align-items: center; 
      :hover {
        transform: rotate(360deg);
      }
      a {
               color:black;
             
             }
           }
`;
