import React from 'react'
import video from '../images/sir_w_video.mp4'
import '../mission/Mission.css'
import { Slide } from "react-awesome-reveal";
const Mission = () => {
  return (
    <div className='dark-bg'>
      <section className="section" id="about">
        <div className='about-section-border pt-5 pb-5'>
          <div className="container-sm">
            <div className="row align-items-center">
              <div className="col-lg-5">
                < Slide direction="up">
                  <h4 id='about' className="fs-1 text-white fw-medium mission-head text-center  ">
                    About
                  </h4>
                  <p className="personal_description">
                    I'm a results-oriented software engineer with over 15 years+ experience providing successful IT
                    solutions across the tech spectrum. I've built everything from websites and ERP/CRM
                    systems to leading-edge blockchain projects, and successfully delivered 500+ ventures across multi domains.
                    I always staying updated with the latest technologies and best practices to ensure project I handle is not only
                    inventive but also set up for success.
                  </p>
                </Slide>
              </div>
              <div className="offset-1 col-lg-6 video__div">
                {/* < Slide direction="right"> */}
                <video 
                  src={video}
                  autoPlay={true}
                  loop
                  muted
                  className="about-video"
                />  
                {/* </ Slide> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Mission