import React from 'react'
import '../certificates/Certificates.css'
import header from '../images/Headers/3.png'
import { DetailedHeading } from '../mentor/Mentorship';
import { Slide, Zoom } from 'react-awesome-reveal';

const Certificates = ({ certifications }) => {
    return (
        <>
            <div className="row background-image_certificates"
            // style={{ backgroundImage: `url(${image})` }}
            >
                <img src={header} alt='Awais Ahmad Khan - Awards' width={'100%'} />

                <div className='col-lg-12'>
                    <div className="row">
                        {/* <img src={image} style={{height:'200px'}}/> */}
                        <DetailedHeading
                            // preHeading={"Certificates"}
                            Heading={"Certificates"}
                        />
                    </div>
                </div>
            </div>
            <div className='container certifications'>
                <div className='row'>
                    {certifications.map((item) => (
                        // <div className="">
                        <div key={item.id} className='col-md-6 certificates'>
                            <Zoom direction="right">
                                <img src={item.img} alt={item.link} className="img-fluid certificate-img" loading="lazy" />
                            </Zoom>
                        </div>
                        //   </div>
                    ))}
                </div>
            </div>
            <div className="skill_shadow_bottom">
            </div>
        </>
    )
}

export default Certificates