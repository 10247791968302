import React from 'react'
import './Podcasts.css'
import { Box, Container, ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery } from '@mui/material'

const Podcasts = () => {
    const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');

    return (
        <Container maxWidth={'xl'}>
            <Typography variant='h2' textAlign={'center'} mt={isMobileOrTablet ? '22%' : '12%'} mb={'5%'} fontWeight={'bold'}>
                Executive Insights with Owais Ahmad
            </Typography>
            <Box className='scroll-bar' sx={{ width: '100%', height: 1000, overflowY: 'scroll' }}>
                <ImageList variant="masonry" cols={3} gap={12}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            {/* <img
                                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=248&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                            /> */}
                            {item.img}
                            <ImageListItemBar position="below" 
                            // title={item.author}
                             />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>

        </Container>
    )
}

export default Podcasts
const itemData = [
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/QyDegpQF87g?si=zbZz_gxi6mto3vH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
      title: 'Bed',
      author: 'swabdesign',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/o8ShUpFYsWM?si=UyodYVnulp_0mqo7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
      title: 'Books',
      author: 'Pavel Nekoranec',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/EkE2edVvKns?si=0XTJFuDY_jcIKahq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
      title: 'Sink',
      author: 'Charles Deluvio',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/-gP0MTbFavs?si=M-g8ywdU7oFfMdwK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
      title: 'Kitchen',
      author: 'Christian Mackie',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/vviC_C04OUU?si=eG63CydbYiQ2K0qJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
      title: 'Blinds',
      author: 'Darren Richardson',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/Ztj40--lpOA?si=oRMCdc8X8b4-1x9Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
      title: 'Chairs',
      author: 'Taylor Simpson',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/rILWROBe_l8?si=28BeAIVnEcPoWZVD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
      title: 'Laptop',
      author: 'Ben Kolde',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/N8cAHvko63Y?si=Z3BvIozDOpNfMUYe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
      title: 'Doors',
      author: 'Philipp Berndt',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/eKvoR8twJVg?si=Ek67kbcrbYIva8QU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
      title: 'Coffee',
      author: 'Jen P.',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/cmkb2ZyFP4A?si=G3rIWaUQJLB0uKhR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
      title: 'Storage',
      author: 'Douglas Sheppard',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/d6YWAQfNDHI?si=_muvfLfrBmxv-qy8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
    //   img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
      title: 'Candle',
      author: 'Fi Bell',
    },
    {
      img: <iframe width="450" height="315" src="https://www.youtube.com/embed/teqYPQ_KmJ0?si=Xg7J9hgVrOHr5CEj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
      title: 'Coffee table',
      author: 'Hutomo Abrianto',
    },
  ];