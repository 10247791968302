import React, { useRef } from 'react'
import Slider from 'react-slick';
import Project from './Project';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';
import img1 from '../images/yt thumbnails/zai thumbnail design 1.png'
import img2 from '../images/yt thumbnails/zai thumbnail design 2.png'
import img3 from '../images/yt thumbnails/zai thumbnail design 3.png'
import img4 from '../images/yt thumbnails/zai thumbnail design 4.png'
import img5 from '../images/yt thumbnails/zai thumbnail design 5.png'

let data = [
    {
      videoEmbed:'<iframe  src="https://www.youtube.com/embed/0wG4hV4Gxe4?si=306t0Rzbhm-hKxyT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    {
      videoEmbed:'<iframe  src="https://www.youtube.com/embed/s22IMJmwmDg?si=DxxBnzr_achZPtTh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    {
      videoEmbed:'<iframe  src="https://www.youtube.com/embed/Tw01oabxoEk?si=gAFFfzVTa6e8Xq1u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    {
      videoEmbed:'<iframe  src="https://www.youtube.com/embed/fJ87ylwDJU8?si=wJSNsNQdQocDaxd6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    },
    {
      videoEmbed:'<iframe  src="https://www.youtube.com/embed/3wJGCI6l7M0?si=sZ3-lLu-Sqe181Hn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
    }
];
// let image = [
//   {
// image:img1 ,
// link:"https://youtu.be/3wJGCI6l7M0?si=6p8DQ2rW7W84aOAU"
//  },
//   {
// image:img2 ,
// link:"https://youtu.be/3wJGCI6l7M0?si=6p8DQ2rW7W84aOAU"
//  },
//   {
// image:img3 ,
// link:"https://youtu.be/3wJGCI6l7M0?si=6p8DQ2rW7W84aOAU"
//  },
//   {
// image:img4 ,
// link:"https://youtu.be/3wJGCI6l7M0?si=6p8DQ2rW7W84aOAU"
//  },
//   {
// image:img5 ,
// link:"https://youtu.be/3wJGCI6l7M0?si=6p8DQ2rW7W84aOAU"
//  }
// ];
var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode : false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode : false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode : false
        }
      }
    ]
  };
const SliderComp = () => {
  const arrowRef = useRef(null);
    let sliderProject = "";
    sliderProject = data.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
      {sliderProject}
      </Slider>
      <Buttons>
        <button 
        onClick={() => arrowRef.current.slickPrev()}
        className='back'><IoIosArrowBack/></button>
        <button 
        onClick={() => arrowRef.current.slickNext()}
        className='next'><IoIosArrowForward/></button>
      </Buttons>
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.100);
    cursor: pointer;
    color: #01be96;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back{
    left: -1rem;
  }
`