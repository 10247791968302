import React from 'react'
import { Link } from "react-router-dom";
// import logo from '../images/logo nav.webp';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import Logo from '../Banner/Logo';

const RightSection = () => {
    return (
        <div className="w-75">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="fs-5 fw-bold">
                        {/* <img
                            src={logo}
                            alt="'Awais Ahmad"
                        /> */}
                        <Logo/>
                    </div>
                    <div className="subtitle mt-3 mb-3">
                        Why do they work weill, and when do they on us and finally, shen od we actually need how can we avoid them
                    </div>
                    <div className='mt-3 mb-3'>
                        <WifiCalling3OutlinedIcon fontSize='large' sx={{ color: '#10B676' }} />
                        <span className='contact-number  ms-3'>
                            +92 322 422 1287
                        </span>
                    </div>
                    <div className='mt-3 mb-3'>
                        <AlternateEmailOutlinedIcon fontSize='large' sx={{ color: '#10B676' }} />
                        <span className='contact-number   ms-3'>
                            owasi.ahmed60@gmail.com
                        </span>
                    </div>

                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="ms-4">
                        <div className="fs-4 mt-3 footer-heading">
                            USEFUL LINKS
                        </div>
                        <div className="mt-4 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Home
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                About
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Experience
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Certificate
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Award
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Contact
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Testimonial
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="ms-4">
                        <div className="fs-4 mt-3 footer-heading">
                            USEFUL LINKS
                        </div>
                        <div className="mt-4 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Contact
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Privacy Policy
                            </Link>
                        </div>
                        <div className="mt-3 mb-3 fs-5">
                            <Link to={'/'} className="footer-link">
                                Terms & Conditions
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RightSection
