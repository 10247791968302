import React from 'react'
import '../awards/Awards.css'

import { DetailedHeading } from '../mentor/Mentorship';
import header from '../images/Headers/2.png';


const Awards = ({awards}) => {
    return (
        //     <div className='container awards'>
        //     <div className='row '>
        //       <div className='col-lg-12'>
        //       <div className="row">
        //           <DetailedHeading
        //               preHeading={"Awards"}
        //               Heading={"Awards"}
        //           />
        //       </div>
        //       </div>
        //     </div>
        //     <div className='row'>
        //       {awards.map((item) => (

        //         <div key={item.id} className='col-md-6 award'>
        //         <img src={item.img} alt={item.link}/>
        //         </div>

        //       ))}
        //     </div>

        //   </div>
     <>
                 <img src={header} alt='Awais Ahmad Khan - Awards' width={'100%'} />
                 <div className='container awards'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className="row">
                        <DetailedHeading
                            // preHeading={"Awards"}
                            Heading={"Awards"}
                        />
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                {awards.map((item) => (
                    <div key={item.id} className='col-md-5 award'>
                        <img src={item.img} alt={item.link} className="img-fluid award-img" />
                    </div>
                ))}
            </div>
        </div>
        <div className="skill_shadow_bottom">
            </div>
</>
    )
}

export default Awards