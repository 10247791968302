import React, { useState } from 'react'

const MathCaptcha = ({ onSubmit }) => {
    const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
    const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
    const [userAnswer, setUserAnswer] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState(num1 + num2);

    const handleChange = (e) => {
        setUserAnswer(e.target.value);
    };

    const handleSubmit = () => {
        if (userAnswer == correctAnswer) {
            onSubmit(); // Call the onSubmit function passed from the parent component
        } else {
            alert('Incorrect answer. Please try again.');
        }
    };
    return (
        <div className='captcha'>
            <div className='math-captcha'>
                <p>{num1} + {num2} = ?</p>
                <input type="text" value={userAnswer} onChange={handleChange} />
                <button className="btn-default btn-send" onClick={handleSubmit}>Send Now <i class="fa-solid fa-paper-plane"></i></button>

            </div>

            
           
        </div>
    )
}

export default MathCaptcha