import React from 'react'
import { Slide } from 'react-awesome-reveal';
import Slider from "react-slick";
import '../about/Slider.css'
import backgroundImg from '../images/bg/home.webp';
// import { BannerActivation } from '../utils/script';
export const BannerActivation = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
};
export const ExpertiseData = [
    {
        id: 1,
        head: `Front-End Development`,
        text: `I’m a pro at building captivating and responsive user experiences with React JS,
         Angular, TypeScript, and Bootstrap 5.`,
        backgroundImage: backgroundImg,
    },
    {
        id: 2,
        head: `Back-End Mastery`,
        text: `I bring robust back-end solutions to the 
        table using Asp .NET Core, C#, Entity Framework, Node JS, and Blockchain.`,
        backgroundImage: backgroundImg,
    },
    {
        id: 3,
        head: `Versatile Toolbox`,
        text: `My diverse skillset includes HTML5, CSS3/Sass,
         Python Django, and Java, allowing me to tackle any project requirement.`,
        backgroundImage: backgroundImg,
    },
    {
        id: 4,
        head: `Technical Project Management`,
        text: `I'm an expert in planning, executing, and delivering 
        projects on time and within budget, leveraging industry best practices and methodologies like 
        Agile Scrum (Certified Scrum Master).`,
        backgroundImage: backgroundImg,
    },
    {
        id: 5,
        head: `Product Ownership`,
        text: `I translate business needs into actionable product development
         plans, prioritize features, define user stories, and align roadmaps with organizational goals.`,
        backgroundImage: backgroundImg,
    },
    {
        id: 6,
        head: `Agile Coaching`,
        text: `I'm passionate about fostering Agile mindsets and behaviors within
         organizations, facilitating ceremonies, coaching teams, and championing Agile transformation.`,
        backgroundImage: backgroundImg,
    },
    {
        id: 7,
        head: `Leadership & Management`,
        text: `I build high-performing teams through principled
         leadership and fostering a healthy work environment. With strong project management,
          negotiation, and analytics, I've led teams to achieve remarkable results.`,
        backgroundImage: backgroundImg,
    },
    {
        id: 8,
        head: `IT Consulting`,
        text: `I provide strategic IT consulting services, helping organizations optimize 
        technology investments, improve efficiency, and drive digital transformation.`,
        backgroundImage: backgroundImg,
    }
]

const Expertise = () => {
    return (
        <>
            <div className="container">
                <div className="row">

                    <Slide direction="down">
                        <h1 className="text-center">
                            My<span className="green"> Expertise</span>
                        </h1>

                    </Slide>
                </div>
            </div>

            {/* <Slider className='slider-area2 slider-style-4 slider-dot rn-slick-dot rn-slick-arrow m-0'
            >

                {ExpertiseData.map((item) => (
                    <div key={item} className='single-slide' >
                        <div className='expertise-slider' style={{ backgroundImage: `url(${item.backgroundImage})` }}>
                            <div key={item.id} className="container">
                                <div className="row" >
                                    <div className="col-md-12 expertise-data" >
                                        <h3 className='expertise-head'>{item.head}</h3>
                                        <p className='expertise-text'>{item.text}</p>
                                    
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                ))}
            </Slider> */}
            <Slider
                className='slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow'
                {...BannerActivation}
            >
                {ExpertiseData.map((data, index) => (
                    <div key={index} className='single-slide'>
                        <div
                            className='height-750 bg_image'
                            data-black-overlay='8'
                            style={{
                                backgroundImage: `url(${data.backgroundImage})`
                            }}
                        >
                            <div className='container'>
                                <div className='row row--30 align-items-center'>
                                    <div className='col-12'>
                                        <div className='inner text-center'>
                                            <h4
                                                className='title'
                                                dangerouslySetInnerHTML={{ __html: data.head }}
                                            ></h4>
                                            <h5>{data.heading}</h5>
                                            <p
                                                className='description text-center'
                                                dangerouslySetInnerHTML={{ __html: data.text }}
                                            ></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>

        </>
    )
}

export default Expertise