import React from 'react'
import './Expertise.css';
import CircleStats from './CircleStats';

const Expertise = () => {
    return (
        <div className='container mt-5 mb-5'>
            <p className='pre-title d-flex justify-content-center'>
                MY SKILLS
            </p>
            <p className='h1 d-flex justify-content-center'>
                My Expertise.
            </p>
            <div className="mt-5">
                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3  mb-5">
                        <CircleStats percent={'98'} disc={'Adaptability'} />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3  mb-5">
                        <CircleStats percent={'86'} disc={'Problem-Solving'}/>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3  mb-5">
                        <CircleStats percent={'95'} disc={'Attention to Detail'}/>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3  mb-5">
                        <CircleStats percent={'89'} disc={'Collaboration'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expertise
