import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';
import image from '../images/IMG_9999.png'

const HomeContactSection = () => {
    return (
        <div className='container'>
            <p className='h1 d-flex justify-content-center section-title mt-5 mb-4'>
                Lets make something awesome together!
            </p>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <img src={image} alt='Awais Ahmad Khan' width={'75%'} className='contact-img'/>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <form className='mb-5'>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <input type="text" class="form-control no-border-bottom mb-4" placeholder="Your Name*" required />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <input type="text" class="form-control no-border-bottom mb-4" placeholder="Company Name*" required />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <input type="email" class="form-control no-border-bottom mb-4" placeholder="Email Address*" required />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <input type="tel" class="form-control no-border-bottom mb-4" placeholder="Phone Number*" required />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <textarea class="form-control no-border-bottom mb-4" rows={'5'} placeholder="Your Message*" required></textarea>
                            </div>
                        </div>
                        <button type='submit' className='pt-3 pb-3 ps-4 pe-4 rounded-pill submit-btn'>Send Message</button>
                    </form>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className='contact-section-below-grid-container'>
                        <a href="https://www.facebook.com/owais.ahmad60/" target='blank' className='contact-section-below-grid-btn'>
                            <FacebookIcon fontSize='large' sx={{ color: '#1F7BF2' }} />
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className='contact-section-below-grid-container'>
                        <a href="https://www.instagram.com/owais.ahmad60/" target='blank' className='contact-section-below-grid-btn'>
                            <InstagramIcon fontSize='large' sx={{ color: '#F00879' }} />
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className='contact-section-below-grid-container'>
                        <a href="https://www.linkedin.com/in/owaisahmadkhan/" target='blank' className='contact-section-below-grid-btn'>
                            <LinkedInIcon fontSize='large' sx={{ color: '#126BC4' }} />
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className='contact-section-below-grid-container'>
                        <a href="https://www.youtube.com/@ExecutiveInsightsWithOwaisKhan" target='blank' className='contact-section-below-grid-btn'>
                            <YouTubeIcon fontSize='large' sx={{ color: '#FF0808' }} />
                        </a>
                    </div>
                </div>
            </div>
            <p className='h1 section-title2 mt-5'>
                Want to know more about me, tell me about your project or just to say a hello? <span className='section-title2-span'>Drop me a line</span> and I'll get back as soon as possible.
            </p>
            <hr />
        </div>
    )
}

export default HomeContactSection
