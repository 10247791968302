import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import './Gallery.css';
import { Container, Typography } from '@mui/material';
import image1 from '../images/gallery/IMG_0001.webp';
import image2 from '../images/gallery/IMG_0002.webp';
import image3 from '../images/gallery/IMG_0003.webp';
import image4 from '../images/gallery/IMG_0004.webp';
import image5 from '../images/gallery/IMG_0008.webp';
import image6 from '../images/gallery/IMG_0009.webp';
import image7 from '../images/gallery/IMG_1134.webp';
import image8 from '../images/gallery/IMG_2046.webp';
import image9 from '../images/gallery/IMG_3240.webp';
import image10 from '../images/gallery/IMG_3752.webp';
import image11 from '../images/gallery/IMG_9185.webp';
import image12 from '../images/gallery/IMG_9444.webp';
import image13 from '../images/gallery/IMG_9510.webp';
import image14 from '../images/gallery/IMG_9512.webp';
import image15 from '../images/gallery/IMG_9514.webp';
import image16 from '../images/gallery/IMG_9645.webp';
import image17 from '../images/gallery/IMG_9681.webp';
import image18 from '../images/gallery/IMG_9769.webp';
import image19 from '../images/gallery/IMG_9999.webp';
import { useMediaQuery } from '@mui/material';

export default function MasonryImageList() {
    const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');

    return (
        <Container maxWidth={'xl'}>
            <Typography variant='h2' textAlign={'center'} mt={isMobileOrTablet?'22%':'12%'} mb={'5%'} fontWeight={'bold'}>
                Gallery
            </Typography>
            <Box className='scroll-bar' sx={{ width: '100%', height: 850, overflowY: 'scroll' }}>
                <ImageList variant="masonry" cols={4} gap={25}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            <img
                                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=248&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </Container>
    );
}

const itemData = [
    {
        img: image1,
        title: 'Bed',
    },
    {
        img: image2,
        title: 'Books',
    },
    {
        img: image3,
        title: 'Sink',
    },
    {
        img: image4,
        title: 'Kitchen',
    },
    {
        img: image5,
        title: 'Kitchen',
    },
    {
        img: image6,
        title: 'Chairs',
    },
    {
        img: image7,
        title: 'Laptop',
    },
    {
        img: image8,
        title: 'Doors',
    },
    {
        img: image9,
        title: 'Coffee',
    },
    {
        img: image10,
        title: 'Storage',
    },
    {
        img: image11,
        title: 'Candle',
    },
    {
        img: image12,
        title: 'Coffee table',
    },
    {
        img: image13,
        title: 'Coffee table',
    },
    {
        img: image14,
        title: 'Coffee table',
    },
    {
        img: image15,
        title: 'Coffee table',
    },
    {
        img: image16,
        title: 'Coffee table',
    },
    {
        img: image17,
        title: 'Coffee table',
    },
    {
        img: image18,
        title: 'Coffee table',
    },
    {
        img: image19,
        title: 'Coffee table',
    },
];
