import React from 'react'
import { Slide, Zoom } from 'react-awesome-reveal'

const Calendly = () => {
  return (
    <div className='container'>
              <div className='row'>
              <Zoom >
                        <h1 className="text-center mt-5">
                            Schedule a<span className="green"> Meeting</span>
                        </h1>
                        {/* <h1>What I Do</h1> */}
                    </Zoom>
              </div>
              <div className="div-block-5">
                <div className="html-embed w-embed w-script">
                  <style>
                    {`
            .calendly-inline-widget {
              border-radius: 5px;
              overflow: hidden;
              min-width: 120px;
              height: 700px;
            
              filter: invert(80%) saturate(0);
              opacity: 0.85;
            }
            @media (max-width: 768px) {
              .calendly-inline-widget {
                margin-left: 15px;
                margin-right: 15px;
        

              }
              
            }
          `}
                  </style>
                  <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/owaisahmadkhan"
                    data-processed="true"
                    style={{ position: 'relative' }}
                  >
                    <div className="calendly-spinner">
                      <div className="calendly-bounce1"></div>
                      <div className="calendly-bounce2"></div>
                      <div className="calendly-bounce3"></div>
                    </div>
                    <iframe
                      src="https://calendly.com/owaisahmadkhan/30min?embed_domain=www.abc.com&amp;embed_type=Inline"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      title="Select a Date & Time - Calendly"
                    ></iframe>
                  </div>
                  <script
                    type="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js"
                    async=""
                  ></script>
                </div>
              </div>
            </div>
  )
}

export default Calendly