import { Typography } from '@mui/material'
import React from 'react'
import { Slide } from "react-awesome-reveal";

const CircleStats = ({ percent, disc }) => {
    return (
        <>
            <Slide direction="up">
                <div className="circle-container mt-5 mb-5" style={{ background: `conic-gradient(#0DC980 0% ${percent}%, transparent ${100 - percent}% 100%)` }} >
                    <div className='circle-content'>
                        <div>
                            <Typography variant='h3' textAlign={'center'} color={'white'} fontWeight={'bold'}>
                                {percent}%
                            </Typography>
                            <Typography variant='body1' color={'white'} marginTop={'7%'} textAlign={'center'}>
                                {disc}
                            </Typography>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    )
}

export default CircleStats