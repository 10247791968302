import { Container } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import BasicModal from "./Model";
import thumbmail1 from '../images/podcastThumbnails/Thumbnail1.png'
import thumbmail2 from '../images/podcastThumbnails/Thumbnail2.png'
import thumbmail3 from '../images/podcastThumbnails/Thumbnail3.png'
import thumbmail4 from '../images/podcastThumbnails/Thumbnail4.png'
import thumbmail5 from '../images/podcastThumbnails/Thumbnail5.png'
import thumbmail6 from '../images/podcastThumbnails/Thumbnail6.png'
import Thumbnail7 from '../images/podcastThumbnails/Thumbnail7.png'
import Thumbnail8 from '../images/podcastThumbnails/Thumbnail8.png'
import Thumbnail9 from '../images/podcastThumbnails/Thumbnail9.png'

function AutoPlay() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 500,
        cssEase: "linear"
    };
    return (
        // <Container maxlength={'xl'}>
        <div className="container mb-4">
            <div className='h1 text-center'>
                Featured Podcast
            </div>
            <div className="slider-container">
                <Slider {...settings}>
                    <div>
                        <BasicModal img={thumbmail1} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/QyDegpQF87g?si=zbZz_gxi6mto3vH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>
                    <div>
                        <BasicModal img={thumbmail2} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/o8ShUpFYsWM?si=UyodYVnulp_0mqo7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>
                    <div>
                        <BasicModal img={thumbmail3} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/EkE2edVvKns?si=0XTJFuDY_jcIKahq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>
                    <div>
                        <BasicModal img={thumbmail4} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/-gP0MTbFavs?si=M-g8ywdU7oFfMdwK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>
                    <div>
                        <BasicModal img={thumbmail5} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/vviC_C04OUU?si=eG63CydbYiQ2K0qJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>
                    <div>
                        <BasicModal img={thumbmail6} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/Ztj40--lpOA?si=oRMCdc8X8b4-1x9Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>
                    {/* <div>
                        <BasicModal img={Thumbnail7} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/pXVriyfvLas?si=zPGmdoLywLazZMtU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div> 
                    <div>
                        <BasicModal img={Thumbnail8} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/WL5tg4nTxsk?si=Q0BiQd9uVaqzrlwP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>
                    <div>
                        <BasicModal img={Thumbnail9} iframe={<iframe width="100%" height="450" src="https://www.youtube.com/embed/OnKbGG8k2Gg?si=znm8z2_8dZvZQhx7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>} />
                    </div>*/}
                </Slider>
            </div>
        </div>

        // </Container>
    );
}

export default AutoPlay;


