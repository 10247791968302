import React from 'react'
// import logo from '../images/sir_logo.webp'
import '../Banner/Header.css'
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Header = () => {

    const handleLinkClick = () => {
        const googleDriveLink = 'https://drive.google.com/file/d/192rq4YnNxCI-cIkRvMoPSzPKrFOK-B7u/view?usp=drive_link';
        window.open(googleDriveLink, '_blank');
    };
    return (

        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container-fluid navbar-bg">
                <Link to="/">
                    <Logo />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-list">
                        <li className="nav-item" >

                            <Link to="/" className="nav-link color_nav_li navbar-border" >Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link color_nav_li navbar-border" >About</Link>

                        </li>
                        <li className="nav-item">
                            <Link to="/gallery" className="nav-link color_nav_li navbar-border" >Gallery</Link>

                        </li>
                        <li className="nav-item">
                            <Link to="/podcast" className="nav-link color_nav_li navbar-border" >Podcast</Link>

                        </li>

                        {/* <li className="nav-item">
                            <a className="nav-link color_nav_li navbar-border" href="/skills" >Skills</a>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/skills" className="nav-link color_nav_li navbar-border">Skills</Link>
                        </li>

                        <li className='nav-item ' >
                            <Link to="/certificates" className="nav-link color_nav_li navbar-border">Certifications</Link>

                        </li>
                        <li className="nav-item">
                            <Link to="/awards" className="nav-link color_nav_li navbar-border">Awards</Link>


                        </li>
                        <li className="nav-item">
                            <Link to="/testimonials" className="nav-link color_nav_li navbar-border">Testimonials</Link>

                        </li>
                        <li className="nav-item">

                            <Link to="/contact" className="nav-link color_nav_li">Contact Me</Link>
                        </li>
                    </ul>
                    <button className='navbar-btn' onClick={handleLinkClick}>Hire Me!</button>
                </div>
            </div>
        </nav>
    )
}
const navbarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#333',
    padding: '10px 20px',
};
const logoContainerStyle = {
    width: '90px', // Adjust this value to change the size
    height: 'auto',
};

const svgStyle = {
    width: '100%', // Ensure the SVG scales with the container
    height: '100%',
};
export default Header
