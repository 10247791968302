import React from 'react'
import '../Footer/FooterTwo.css'
const FooterTwo = () => {
    return (
        // <div className="footer-alt">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-sm-12">
        //                 <p className='last-footer'> © 2024 All Rights Reserved By <a href="https://www.zaisystems.com/">zaisystems</a></p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="footer-alt">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 d-flex align-items-center justify-content-center">
                        <p className='last-footer'>© 2024 – All Rights Reserved By
                         <a href="https://www.zaisystems.com/" target="_blank"> Zai Systems</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterTwo