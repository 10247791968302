import React from 'react'
import styled from 'styled-components';

const Project = (props) => {
    const { image, disc ,link, videoEmbed} = props.item;
  return (
    <Container className='project'>
           <div dangerouslySetInnerHTML={{ __html: videoEmbed}} />
           {/* <a href={link} target="_blank" rel="noopener noreferrer"> 
                <img src={image} alt="project" />
            </a> */}
  
    </Container>
  )
}

export default Project;

const Container = styled.div`
    height: 10rem;
    background-color: #4e5156;
    margin: 0 0rem;
    padding:5px 3px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 400ms ease-in-out;
    }
    .disc{
        position: absolute;
        right: 0;
        left: 0;
        bottom: -10rem;
        text-align: left;
        padding: 0.5rem;
        background: linear-gradient(rgba(0,0,0, 0.100), rgba(0,0,0, 0.80));
        transition: all 400ms ease-in-out;
        h1{
            font-size: 1rem;
        }
    
        p{
            width: 90%;
            font-size: 0.8rem;
            a{
                margin-left: 0.4rem;
                color: red;
            }
        }
    }

   

    :hover > .disc{
        bottom: 0;
    }

`
//  :hover > img{
//         transform: scale(1.3);
//     }