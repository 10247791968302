import React from 'react'
import Slider from 'react-slick'

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <button className="slick-arrow slick-next">Next</button>,
    prevArrow: <button className="slick-arrow slick-prev">Prev</button>,
    responsive: [
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 530,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
};

const AwardsCarousel = ({awards}) => {
  return (
<div className="container">
            <p className='h1 ms-auto me-auto d-flex d-flex justify-content-center section-title mt-5'>
                AWARDS
            </p>
            <div className="carousel-container mt-5">
                <Slider {...settings}>
                    {awards.map((item, index) => (
                        <div key={index} className="carousel-item">
                            <img src={item.img} width={'100%'} alt={`item-${index}`} />
                            <p>{item.title}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
  )
}

export default AwardsCarousel



// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
// import { Button } from '@mui/material'
// import { Slide } from 'react-awesome-reveal';
// import styled from 'styled-components'
// import React, { useRef } from 'react'
