
import Skills from "./components/Skills/Skills";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./components/home/Home";
import Header from "./components/Banner/Header";
import AnimCursor from "./components/AnimCursor";
import Contact from "./components/contact/Contact";
import Footer from "./components/Footer/Footer";
import FooterTwo from "./components/Footer/FooterTwo";
import styled from "styled-components";
import About from "./components/about/About";
import Certificates from "./components/certificates/Certificates";
import Awards from "./components/awards/Awards";
import Textimonials from "./components/Clients/Textimonials";

import certificate1 from './components/images/certificates/NG Study.webp';
import certificate2 from './components/images/certificates/Management and Strategy Institute.webp';
import certificate3 from './components/images/certificates/6Sixma Study.webp';
import certificate4 from './components/images/certificates/EVS.webp';
import certificate5 from './components/images/certificates/UoL Innovation.webp';
import certificate6 from './components/images/certificates/Society Of computer Science.webp';
import certificate7 from './components/images/certificates/EVS Professional Training Institute.webp';
import certificate8 from './components/images/certificates/CAREER COUNSELING.webp';
import certificate9 from './components/images/certificates/Society of CS & SE.webp';
import certificate10 from './components/images/certificates/WorkShop On Python.webp';
import certificate11 from './components/images/certificates/BlockChain Technology & Its Applications.webp';
import certificate12 from './components/images/certificates/FreeLancing WorkShop.webp';

import award1 from './components/images/awards/1.webp';
import award2 from './components/images/awards/2.webp';
import award3 from './components/images/awards/3.webp';
import award4 from './components/images/awards/4.webp';
import award5 from './components/images/awards/5.webp';
import award6 from './components/images/awards/6.webp';
import award7 from './components/images/awards/7.webp';
import award8 from './components/images/awards/8.webp';
import award9 from './components/images/awards/9.webp';
import award10 from './components/images/awards/10.webp';
import award11 from './components/images/awards/11.webp';
import award12 from './components/images/awards/12.webp';
import award13 from './components/images/awards/13.webp';
import award14 from './components/images/awards/14.webp';
import award15 from './components/images/awards/15.webp';
import award16 from './components/images/awards/16.webp';
import award17 from './components/images/awards/17.webp';
import award18 from './components/images/awards/18.webp';
import MasonryImageList from "./components/Gallery/Gallery";
import Podcasts from "./components/Podcasts/Podcasts";

function App() {

  const certifications = [
    {
        link: 'EVS Professional Training Institute',
        ref: 'Certified ASP.net Core Specialist',
        img: certificate1
    }
    , {
        link: "UOL INNOVATION OLYMPIAD'19",
        ref: 'Website Development',
        img: certificate2
    }
    , {
        link: 'Society Of computer Science (UOL)',
        ref: 'Web Development',
        img: certificate3
    }
    , {
        link: 'EVS Professional Training Institute',
        ref: 'Certified ASP.net Core Specialist',
        img: certificate4
    }
    , {
        link: "UOL INNOVATION OLYMPIAD'19",
        ref: 'Website Development',
        img: certificate5
    }
    , {
        link: 'Society Of computer Science (UOL)',
        ref: 'Web Development',
        img: certificate6
    },
    {
        link: 'EVS Professional Training Institute',
        ref: 'Web Development',
        img: certificate7
    },
    {
        link: 'CAREER COUNSELING',
        ref: 'University Of Lahore',
        img: certificate8
    },
    {
        link: 'Society of CS & SE',
        ref: 'Web Development',
        img: certificate9
    },
    {
        link: 'WorkShop On Python',
        ref: 'University Of Lahore',
        img: certificate10
    },
    {
        link: 'BlockChain Technology & Its Applications',
        ref: 'University Of Lahore',
        img: certificate11
    },
    {
        link: 'FreeLancing WorkShop',
        ref: 'University Of Lahore',
        img: certificate12
    }
];

const awards = [
  {

      img: award14
  },
  {

      img: award15
  },
  {

      img: award16
  },
  {

      img: award1
  },

  {

      img: award8
  },
  {

      img: award9
  },

  {

      img: award11
  },

  {

      img: award13
  },
 {

      img: award4
  }
  , {

      img: award5
  }
  , 
  {

      img: award17
  },
  {

      img: award18
  },
  {

      img: award6
  },
  {

      img: award7
  },
 
  {

      img: award12
  },
  {

      img: award3
  }
];

  return (
<>

    <Router>
      <Header/>
    {/* <AnimCursor/> */}
    <Routes>
      <Route path="/" element={<Home certifications={certifications} awards={awards}/>} />
     {/**/}  <Route path="/skills" element={<Skills />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About/>} />
      <Route path="/gallery" element={<MasonryImageList/>} />
      <Route path="/podcast" element={<Podcasts/>} />
      <Route path="/certificates" element={<Certificates certifications={certifications}/>} />
      <Route path="/awards" element={<Awards awards={awards}/>} />
      <Route path="/testimonials" element={<Textimonials/>} />

    </Routes> 
    {/**/} <LightColor>
       <Footer/>
    <FooterTwo/> 
    </LightColor>  
  </Router></>
  );
}

export default App;
  // background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%)
const LightColor = styled.div`
background-color:#191923;
`;
