import React from 'react'
import Services from '../Service/Services'
import Experience from './Experience'

import styled from "styled-components";

import { Slide } from "react-awesome-reveal";
import Separator from '../Separator';
import { DetailedHeading } from '../mentor/Mentorship';
import Expertise from './Expertise';
const About = () => {
  // const cursorStyle = {
  //   cursor: 'pointer'
  // };style={cursorStyle}
  return (
    <>
      <Container id="service" >


        {/* <div className="row">
          <DetailedHeading
            preHeading={"About Me"}
          // Heading={"Certificates"}
          />
        </div> */}

        {/* <p className="text-center">I'm a passionate and results-oriented software engineer with over 15 years of experience
           crafting creative and successful solutions across the tech spectrum. I've built everything
            from websites and ERP/CRM systems to cutting-edge blockchain projects, always staying
             updated with the latest technologies and best practices to ensure my work is efficient,
              innovative, and impactful.</p> */}
       

 <Expertise />

      </Container>

      {/* <Separator /> */}
      <Separator />

      <Experience />
      <div className="skill_shadow_bottom">
            </div>
    </>
  )
}

export default About;

const Container = styled.div`
  width: 100%;
background: #0e0e10;

  margin: 0 auto;
  padding-top: 5.5rem ;
  padding-bottom:0rem;
  @media (max-width: 840px) {
    width: 100%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Exp = styled.div`
 background:red;
`;