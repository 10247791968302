import React from 'react'
import styled from 'styled-components'
import { IoIosQuote } from "react-icons/io";
import { AiOutlineStar } from "react-icons/ai";

const ClientSlider = (props) => {
    const { name, position, img_url, stars, disc } = props.item;
    return (
        <div>
            <Container>
                <Header>
                    <span className='quote'><IoIosQuote /></span>
                </Header>
                <Body className='ps-4 pe-4'>
                    {disc}
                </Body>
            </Container>
            <div className='d-flex justify-content-center mt-5 mb-2'>
                <svg width="549.5" height="21.7" viewBox="0 0 785 31" fill="none">
                    <path d="M785 1H124.603L87.2222 30V1H0" stroke="white" stroke-width="0.980952" />
                </svg>
            </div>

            <Footer>
                <img src={img_url} alt={name} />
                <div className="details">
                    <h1>{name}</h1>
                    <p>{position}</p>
                </div>
            </Footer>
        </div>
    )
}

export default ClientSlider
// background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
const Container = styled.div`
    background:#191923 ;
    padding: 0rem 1rem;
    margin: 0 1rem;
    height:18rem;
    border: 1.5px solid #0DCB81;
    border-radius: 40px;
        @media(max-width:840px){
    height:28rem;
    }
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .quote{
        font-size: 3rem;
        color: #01be96;
        opacity: 0.7;
    }

    .star{
        color: #ffcd3c;
        font-size: 1rem;
        margin:1px;
    }
`
const Body = styled.p`
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
    height:22rem;
`
const Footer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    img{
        width: 4rem;
        height: 4rem;
        border-radius: 50px;
        object-fit: cover;
    }

    h1{
        font-size: 1.2rem;
        font-weight: 700;
        @media(max-width: 580px){
            font-size: 1rem;
        }
        @media(max-width: 538px){
            font-size: 0.9rem;
        }
    }

    p{
        font-size: 0.8rem;
        color: rgba(255,255,255,0.500);
        @media(max-width: 538px){
            font-size: 0.6rem;
        }
    }
`