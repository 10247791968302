import emailjs, { init } from "@emailjs/browser";
import React, { useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import '../contact/Contact.css';
import { Slide } from "react-awesome-reveal";
import MathCaptcha from "./MathCaptcha";
import Calendly from "./Calendly";
import Separator from "../Separator";
import header from '../images/Headers/1.png';

const Contact = () => {
    const form = useRef();
    init("8r6iUINi3pC_HOCoL");

    const handleSubmit = (e) => {

        e.preventDefault();
        emailjs
            .sendForm(
                "service_5hujz4g",
                "template_epxon3y",
                form.current,
                "8r6iUINi3pC_HOCoL"
            )
            .then(
                (result) => {
                    // alert("Message sent Successfully");
                    form.current.reset();
                    toast.success("Message sent Successfully");
                },
                (error) => {
                }
            );
        console.log('Form submitted successfully');
    };
    const cursorStyle = {
        cursor: 'pointer'
    };

    return (
        <>
            <div className="dark-bg">
                <img src={header} alt="Awais Ahamd Kahn - Contact" width={'100%'} />
                <Slide direction="top" className="contact_bg" style={cursorStyle}>
                    <div className="container d-flex justify-content-center align-items-center vh-80 ">
                        <div className="row">
                            <div className="col-md-12">

                                <h3 className="text-center mb-3 contact_heading">Email Me ✉️</h3>

                                <form onSubmit={handleSubmit} ref={form}>
                                    <div className="form-group">
                                        <input type="text" name="fullname" placeholder="Your Name" required autoComplete="off" />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                            required
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input type="text" name="phone" placeholder="Phone Number" required autoComplete="off" />
                                    </div>

                                    {/* <div className="form-group">
                            <input type="text" name="subject" placeholder="Subject" required autoComplete="off" />
                        </div> */}

                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            placeholder="Your Message✏️"
                                            required
                                        ></textarea>
                                    </div>
                                    <MathCaptcha onSubmit={handleSubmit} />
                                    {/* <div className="form-group">
                                    <button className="btn-default btn-send">Send Now <i class="fa-solid fa-paper-plane"></i></button>
                                </div> */}
                                </form>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </Slide>
                <Separator /></div>
            <Calendly />
            <div className="skill_shadow_bottom">
            </div></>
    );
}
export default Contact