import React from "react";
import styled from "styled-components";
import { MdAlternateEmail } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMailOpen } from "react-icons/hi";
import { AiFillGithub, AiFillLinkedin, AiOutlineArrowUp } from "react-icons/ai";
import { BsFacebook, BsSlack } from "react-icons/bs";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { Slide, Zoom, Fade } from "react-awesome-reveal";
import ScrollTop from "../scroll/ScrollTop";
import FooterTwo from "./FooterTwo";
import '../Footer/FooterTwo.css'
import RightSection from "./RightSection";
import LeftSection from "./LeftSection";

const Footer = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="d-flex">
        <div className="container-left w-75 d-flex justify-content-center align-items-center">
          <RightSection/>
        </div>
        <div className="container-right w-25 pt-5 pb-5">
          <LeftSection/>
        </div>
      </div>
      {/* <div className="container footerOne">
        <div className="row" style={{width:'100%'}}>
          <div className="col-md-6">

            <h4 className="footer-head">Drop Me a Line!</h4>

            <div className="links">
              <div className="footer-phone">
                <Zoom > <span>
                  <i class="fa-solid fa-square-phone"></i>
                </span>
                  <a href="tel:+92 322 422 1287">+92 322 422 1287</a></Zoom>
              </div>
              <div className="footer-mail">
                <Zoom >  <span>
                  <i class="fa-solid fa-envelope"></i>
                </span>
                  <a href="mailto:owais.ahmad60@gmail.com">owais.ahmad60@gmail.com</a> </Zoom>
              </div>

            </div>

          </div>
          <div className="col-md-6">
            <div className="profiles">

              <h4 className="footer-head2">Find Me Out & Get Connected!</h4>

              <div className="icons">
                <Zoom>
                  <span>
                    <a href="https://www.linkedin.com/in/owaisahmadkhan/" target="_blank">
                    <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </span>
                </Zoom>
                <Zoom>
                  <span>
                    <a href="https://www.facebook.com/owais.ahmad60?mibextid=2JQ9oc" target="_blank">
                    <i class="fa-brands fa-facebook"></i>
                    </a>
                  </span>
                </Zoom>
                <Zoom>
                  <span>
                    <a href="https://www.instagram.com/owais.ahmad60?igsh=MTNodGhuMndibnI5NA==" target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                    </a>
                  </span>
                </Zoom>

              </div>
            </div>
          </div>
        </div>
      </div> */}






      {/* <Form>
        <Slide direction="right">
          <form>
            <div className="name">
              <span>
                <CgProfile />
              </span>
              <input type="text" placeholder="Fullname..." />
            </div>
            <div className="email">
              <span>
                <MdAlternateEmail />
              </span>
              <input type="email" placeholder="Email..." />
            </div>
            <div className="message">
              <span className="messageIcon">
                <FiMail />
              </span>
              <textarea cols="30" rows="10" placeholder="Message..."></textarea>
            </div>
            <button>Submit</button>
          </form>
        </Slide>
      </Form> */}
      <ScrollTop />

    </>

  );
};

export default Footer;



