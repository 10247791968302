import React from 'react'
import { Link } from 'react-router-dom';

const LeftSection = () => {
    return (
        <div>
            <div className="h3 text-center top-heading">
                Have a project in mind?
            </div>
            <div className="d-flex justify-content-center pt-5 pb-5">
                <Link to="/contact" className="nav-link color_nav_li navbar-border">
                    <button className='footer-contact-me-btn '>Contact Me</button>
                </Link>
            </div>
            <div className="text-center fs-5">
                09:00 AM - 10:30 PM
            </div>
            <div className="text-center subtitle">
                Monday to Saturday
            </div>
        </div>
    )
}

export default LeftSection
