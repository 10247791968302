import React from 'react'
import { Link } from 'react-router-dom';
import '../mentor/Mentorship.css';
import { Slide, Zoom } from "react-awesome-reveal";
import Separator from '../Separator.jsx';

export const MentorData = [
    {
        designation: "Full Stack Software Development Mentor",
        company: "ZAI SYSTEMS (SMC-Private) Limited",
        tenure: "Jan 2015 – Present"
    },
    {
        designation: "Full Stack Web Development, Blockchain Mentor",
        company: "NAVTTC ",
        tenure: "Jul 2022 – Present"
    },
    {
        designation: "Member of the Industrial Advisory Board (IAB)",
        company: "University of Lahore",
        tenure: "2020 –Present"
    },
    {
        designation: "Full Stack Mentor",
        company: "EVS professional training institute",
        tenure: "Sep 2021 – April 2022"
    },
    {
        designation: "Taught Programming courses",
        company: " University Students",
        tenure: "Sep 2019 – Apr 2020"
    }
]
export const ExperienceData = [
    {
        id: 1,
        designation: 'The Founder / CEO ',
        tenure: " Jan 2015 – Present ",
        company: 'ZAI SYSTEMS (SMC-Private) Limited '

    },
    {
        id: 2,
        designation: 'Technical Project Manager',
        tenure: "Feb 2023-Present",
        company: 'Soloinsight -Cloud Gate Platform'
    },
    {
        id: 3,
        designation: 'Product Manager / Scrum Master',
        tenure: "May 2022 - Jan 2023",
        company: 'Rodeo Logistics'
    },
    {
        id: 4,
        designation: 'Senior Tech Project Manager Team Lead',
        tenure: "Oct 2020 – April 2022",
        company: 'SIMPLE LOGIX'
    },
    {
        id: 5,
        designation: 'Full Stack Developer /Team lead',
        tenure: "Jan 2020 – Sep 2020",
        company: 'ALCHEMATIVE'
    },
    {
        id: 6,
        designation: 'Full Stack Sr. Microsoft .net Core & MVC5 developer',
        tenure: "Jan 2018 – Nov 2019",
        company: 'SPORTECH'
    },
    {
        id: 7,
        designation: 'Full Stack Web Developer',
        tenure: "June 2014 – Nov 2017",
        company: 'IPSUM SOLUTIONS'
    },
]

export const DetailedHeading = ({ preHeading, Heading, bgHeading, paragraph }) => {
    return (
        <>
            <div className="container">
                <div className="row justify-content-center mb-4">
                    <div className="col-md-12 text-center my_mob_padding">
                        <span
                            style={{
                                background: "linear-gradient(159deg,rgb(45,45,58) 0%,rgb(43,43,53) 100%)",
                                color: "#01be96",
                                padding: "0.5rem 1.2rem",
                                fontSize: "1rem",
                                fontWeight: "600 !important"
                            }}
                            className="badge badge-lg rounded bg-soft-alt-success fw-normal fs-13"
                        >
                            {preHeading}
                        </span>
                        {/* <h2 className="fs-2 fw-medium lh-1 text-light my-4 position-relative text-center">
                            {Heading}
                        </h2> */}

                    </div>
                    {/*end col*/}
                </div>
            </div>
        </>
    );
};
const Mentorship = () => {
    return (
        <div className='dark-bg'>
            <div className="container my_container">
                <div className="row">
                    <div className="col-md-12">
                        <DetailedHeading
                            // preHeading={"About"}
                            Heading={"Mentorship"}
                        />
                    </div>
                </div>
                <div className="row experience_row">
                    {MentorData.map((item, index) => {
                        return (
                            <div className="parent">
                                {index < 5 && (
                                    <div className={`d-flex justify-content-center flex-row experience_parent`}>
                                        <div
                                            className="col-lg-6 col-sm-5 left_div"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",

                                            }}
                                        >
                                            <Slide direction="left">
                                                <h5 className="designation">{item.designation}</h5>
                                                <p className="tenure">{item.tenure}</p>
                                            </Slide>
                                        </div>
                                        <div className="col-lg-1 col-sm-1 center_div">
                                            <div className="my_circle"></div>
                                            <div className="my_line"></div>
                                        </div>
                                        <div className="col-lg-5 col-sm-5 right_div">
                                            <Slide direction="right">
                                                <h5 className="company">  {item.company}</h5> </Slide>
                                            {/* <p className="details">{item.statement}</p> */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                {/* <div className="row btn_row">
                <div className="d-flex justify-content-center">
                    <Link to={"/about"}>
                        <button type="button" class="btn my_btn">
                            See more
                        </button>
                    </Link>
                </div>
            </div> */}
            </div>
            <Separator />
            <div className="container my_container">
                <div className="row">
                    <DetailedHeading
                        Heading={"Corporate Experience"}
                    />
                </div>
                <div className="row experience_row">
                    {ExperienceData.map((item, index) => {
                        return (
                            <div className="parent">
                                {index < 5 && (
                                    <div className={`d-flex justify-content-center flex-row experience_parent`}>
                                        <div
                                            className="col-lg-6 col-sm-5 left_div"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",

                                            }}
                                        >
                                            <Slide direction="left">
                                                <h5 className="designation">{item.designation}</h5>
                                                <p className="tenure">{item.tenure}</p>
                                            </Slide>
                                        </div>
                                        <div className="col-lg-1 col-sm-1 center_div">
                                            <div className="my_circle"></div>
                                            <div className="my_line"></div>
                                        </div>
                                        <div className="col-lg-5 col-sm-5 right_div">
                                            <Slide direction="right">
                                                <h5 className="company">  {item.company}</h5> </Slide>
                                            {/* <p className="details">{item.statement}</p> */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>







                <div className="row btn_row">
                    <div className="d-flex justify-content-center">
                        <Link to={"/about"}>
                            <button type="button" class="btn my_btn">
                                See more
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Mentorship