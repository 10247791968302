import React from "react";
import { MdDesignServices } from "react-icons/md";
import { FiCodesandbox } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import '../Service/Services.css';
import { Slide } from "react-awesome-reveal";
import PropTypes from 'prop-types';
import '../mentor/Mentorship.css';
import { Box, Tab, Tabs, TabContext, TabList, } from "@mui/material";
import GridItem from "./GridItem";
import FirstSection from "./FirstSection";
import { useMediaQuery } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
    role="tabpanel"
    hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Services = () => {
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="container mt-5">
      <FirstSection />
      <div className='h1 fw-bold mt-5'>
        My Services
      </div>
      <hr />
      <Box sx={{ width: '100%', my: '3%' }}>
        <div className="service-circle-svg-container">
          <div className="service-circle-svg">
            <svg width="636" height="636" viewBox="0 0 636 636" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" d="M218.78 616.513C383.792 671.163 561.863 581.698 616.513 416.686C671.163 251.674 581.698 73.6031 416.686 18.9529C251.674 -35.6973 73.603 53.7684 18.9529 218.78C-35.6973 383.792 53.7684 561.863 218.78 616.513Z" stroke="url(#paint0_linear_629_8589)" stroke-width="5" />
              <defs>
                <linearGradient id="paint0_linear_629_8589" x1="417" y1="18.0036" x2="20.165" y2="239.317" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#0DCB81" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>

        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="FrontEnd"
              sx={{
                color: "white",
                mx: isMobileOrTablet ? '0%' : '8.25%'
              }}
              {...a11yProps(0)} />
            <Tab label="BackEnd"
              sx={{
                color: "white",
                mx: isMobileOrTablet ? '0%' : '8.25%'
              }}
              {...a11yProps(1)} />
            <Tab label="Database"
              sx={{
                color: "white",
                mx: isMobileOrTablet ? '0%' : '8.25%'
              }}
              {...a11yProps(2)} />
            <Tab label="Others"
              sx={{
                color: "white",
                mx: isMobileOrTablet ? '0%' : '8.25%'
              }}
              {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'01'} heading={'HTML 5'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'02'} heading={'CSS 3'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'03'} heading={'Bootstarp 5'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'04'} heading={'Javascript'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'05'} heading={'React'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'06'} heading={'Angular'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'01'} heading={'PHP (Laravel)'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'02'} heading={'Express'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'03'} heading={'Node'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'04'} heading={'c Sharp (c#)'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'05'} heading={'Asp.net Core'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'06'} heading={'Python'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'01'} heading={'HTML 5'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'02'} heading={'CSS 3'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'03'} heading={'Bootstarp 5'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'04'} heading={'Javascript'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'05'} heading={'React'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'06'} heading={'Angular'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'01'} heading={'HTML 5'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'02'} heading={'CSS 3'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'03'} heading={'Bootstarp 5'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'04'} heading={'Javascript'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'05'} heading={'React'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <GridItem number={'06'} heading={'Angular'} subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."} />
            </div>
          </div>
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 2rem;
  gap: 1rem;
`;
