import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ClientSlider from './ClientSlider';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Slide } from 'react-awesome-reveal';
import zeeshan from '../images/zeeshan.webp';
import zahid from '../images/zahid.webp';
import hassan from '../images/hassan.webp';
import Ahmed from '../images/Ahmed Sohail.webp';
import Hamza from '../images/Hamza.webp';
import Huzaifa from '../images/Huzaifa.webp';
import sabeel from '../images/sabeel.webp';
import waqas from '../images/waqas.webp';
import ushna from '../images/ushna.webp';
import sana from '../images/Sana.webp';
import zonia from '../images/zonia.webp';

let clients = [

    {
        name: "Sana Atif",
        position: "Human Resources Manager - Techtanic",
        img_url: sana,
        stars: 4,
        disc: `I highly recommend Owais for any IT project or leadership role. With over 15 years of diverse experience in web development, ERP/CRM solutions, and advanced blockchain projects, Owais is proficient in various technologies including React JS, Angular, Asp .NET Core, Node JS, Python, and Blockchain. He has a proven track record of delivering over 100 high-quality projects and excels in technical project management, Agile Scrum mastery, and product ownership.
        Owais's technical expertise and strong leadership and management skills make him an ideal candidate for leading and growing high-performing engineering teams. His commitment to staying updated with the latest IT trends, good behavior, and principled leadership ensure that every project he handles is not only creative but also set up for success.`
    }, {
        name: "HUZAIFA ALI",
        position: "REACT & NEXT JS DEVELOPER - SPARK AI",
        img_url: Huzaifa,
        stars: 5,
        disc: `I wanted to work with someone who could help me reach my full potential as a React and Next js developer. I was lucky to work with Sir Owais for a few years, and he played an integral role in helping me develop my skills in this industry. He positively influenced my career trajectory and helped me build confidence in this challenging field. Sir Owais did more than just coach me. He supported me every step of the way and never hesitated to lend a helping hand. He showed me a sense of compassion and understanding that perfectly complemented his business acumen. I’m a much better employee thanks to Sir Owais’s guidance, and anyone would be lucky to work with him.`
    },
    {
        name: "Zonia Maqbool",
        position: "HR Ops Expert - Villaex Technologies",
        img_url: zonia,
        stars: 5,
        disc: `Owais Ahmad Khan epitomizes expertise in technology and entrepreneurship. As an accomplished Entrepreneur and Senior Technical Agile Program Manager, his roles underscore his adaptability in navigating complex business environments. Owais's leadership is characterized by innovation, strategic foresight, and an unwavering commitment to excellence. Under his guidance, Zai System Pvt. Ltd. has thrived, establishing new industry benchmarks. He cultivates a culture of collaboration, creativity, and continuous improvement, driving sustainable growth. I confidently endorse Owais Ahmad Khan as a transformative leader, poised to shape the future of technology with his visionary approach and remarkable capabilities.`
    }
    ,
    {
        name: "Muhammad Zeeshan Tanveer",
        position: "Web Developer - Soloinsight Inc. | Software Engineer",
        img_url: zeeshan,
        stars: 4,
        disc: `I was looking for a software project manager who could handle complex and challenging
         projects with tight deadlines and budgets. I hired Owais Ahmad based on his impressive portfolio
          and credentials. He exceeded my expectations with his exceptional leadership, strategic thinking,
           and technical skills. He delivered every project on time and within budget, while maintaining
            excellent communication and collaboration with the development team. He is a valuable asset
             to any organization seeking a skilled and results-driven software project manager.`
    },
    {
        name: "ZAHID IMAM",
        position: " DIGITAL EVANGELIST - I’M INNOVATOR",
        img_url: zahid,
        stars: 5,
        disc: `Owais Ahmad Khan was our technical project/product manager who had superb expertise in Jira.
         He played a pivotal role in the success of our projects by setting up workflows, dashboards, and 
         reports that optimized our productivity and efficiency. Beyond his technical prowess, Owais stood 
         out for his exceptional cooperation and kindness towards every team member. He fostered a positive
          and collaborative work environment, making him an invaluable asset to any organization. I
           wholeheartedly recommend Owais for his technical acumen and leadership skills.`
    },
    {
        name: "AHMED SOHAIL",
        position: "PRINCIPAL SOFTWARE ENGINEER - CERTIFIED NERDS CYBER SECURITY COMPANY",
        img_url: Ahmed,
        stars: 4,
        disc: `Owais Ahmad worked with us on a huge project and he consistently delivered great quality
         code and service within schedule. He had strong technical skills, analytical skills, and
          communication skills. He was a committed, independent, and reliable team player and I
           believed he would make a contribution to any team he joined.`
    },
    {
        name: "WAQAS LATIF",
        position: "SEARCH ENGINE OPTIMIZATION SPECIALIST - SIMPLESTUDYING",
        img_url: waqas,
        stars: 5,
        disc: `It was amazing working with such an incredible individual who had such expertise in dotnet 
        before even completing his bachelor’s. I was skeptical at first, but he proved me wrong with his 
        professionalism, knowledge, and skill. He was always ready to take on new challenges and learn new things.`
    },
    {
        name: "HASSAN ARIF",
        position: "ASSISTANT MANAGER - DAEWOO PAKISTAN EXPRESS BUS SERVICE LTD",
        img_url: hassan,
        stars: 5,
        disc: `Owais is a hardworking and passionate person who has excellent team leadership skills. He always wrote clean and efficient code that showed the brilliance of his mind. He was always respectful and friendly towards others, and he created a great team spirit. I enjoyed working with him and learned a lot from him.`
    },
    {
        name: "SABEEL AHMED",
        position: "SENIOR UNITY DEVELOPER - GIISTY",
        img_url: sabeel,
        stars: 4,
        disc: `Owais Ahmad is a professional guy who knows his stuff. I worked with him on a few projects and I was impressed by his responsibility, work ethic, and communication skills. He was equally fluent with frontend and backend technologies, and he delivered high-quality work every time. I would love to join him for other projects as well.`
    },
    {
        name: "HAMZA AFZAL",
        position: "WORDPRESS DEVELOPER - S&D MARKETING",
        img_url: Hamza,
        stars: 4,
        disc: `I was Owais Ahmad’s team lead and I found him very dedicated, technically strong, and eager to learn new technologies. He was a very good team player and he could work under minimum supervision. He always worked hard and delivered great work for many challenging tasks. His analytical skills and programming skills were outstanding. He delivered tasks in very tight deadlines with best quality. He was keen to learn new technologies and implement best practices in software development. I wish him best of luck for his social and professional life.`
    },

    {
        name: "Ushna Sadaf Dar",
        position: " HR Generalist - Bloomrix",
        img_url: ushna,
        stars: 5,
        disc: `I had the pleasure of meeting Owais Ahmad Khan when he was a guest speaker. His ability to design and implement a scalable, secure, and user-friendly solution that met the needs of organizations is impressive.

        Owais made significant contributions to the success of HCM 360, including overseeing the entire development process, coordinating with various stakeholders, and ensuring customer satisfaction. His work resulted in a product that improved HR efficiency, productivity, and performance.
        
        Owais Ahmad Khan is a skilled, innovative, and professional leader who would be an asset to any team. He has proven his expertise and excellence in the IT industry.`
    }


]
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const Clients = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <div className="dark-bg">
            <Container id='client' >
                <Slide direction="down">
                    <span className="green">testimonials</span>
                    <h2 className='mt-2'>What people says</h2>
                </Slide>
                <Testimonials>
                    <Slider ref={arrowRef} {...settings}>
                        {clientDisc}
                    </Slider>
                    <Buttons>
                        <button
                            onClick={() => arrowRef.current.slickPrev()}
                        ><IoIosArrowBack /></button>
                        <button
                            onClick={() => arrowRef.current.slickNext()}
                        ><IoIosArrowForward /></button>
                    </Buttons>
                </Testimonials>
            </Container></div>
    )
}

export default Clients

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0;

    @media(max-width:840px){
        width: 90%;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
    }

    h1{
        padding-top: 1rem;
        text-transform: capitalize;
    }

    .slick-list, .slick-slider, .slick-track{
        padding: 0;
    }

    .slick-dots{
        text-align: left;
        margin-left: 1rem;
    }

    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button{
        width: 9px;
        height: 4px;
        background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
        padding: 0.1rem;
        margin-top: 1rem;
        transition: all 400ms ease-in-out;
        border-radius: 50px;
    }
    
    .slick-dots li.slick-active button{
        background: #01be96;
        width: 15px;
    }

    .slick-dots li{
        margin: 0;
    }
`

const Testimonials = styled.div`
    margin-top: 2rem;
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #01be96;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`