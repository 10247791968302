import React from 'react'
import ProfComponent from '../Banner/ProfComponent'
import Header from '../Banner/Header'
import Services from '../Service/Services'
import Skills from '../Skills/Skills'
import Projects from '../Projects/Projects'
import IncrementNumber from '../Increment/IncrementNumber'
import Clients from '../Clients/Clients'
import Footer from '../Footer/Footer'
import styled from "styled-components";
import FooterTwo from '../Footer/FooterTwo'
import Mission from '../mission/Mission'
import Mentorship from '../mentor/Mentorship'
import Experience from '../about/Experience'
import EducationsExperience from '../mentor/EducationsExperience'
import Expertise from '../Expertise/Expertise'
import CertificationsCarousel from '../certificates/CertificationsCarousel'
import AwardsCarousel from '../awards/AwardsCarousel'
import HomeContactSection from '../contact/HomeContactSection'
import AutoPlay from '../Podcasts/PodcastCarousel'
const Home = ({certifications, awards}) => {

  return (
    <Container >
      {/* <Banner> */}
      <ProfComponent />
      {/* </Banner> */}
      <Mission />
      <EducationsExperience />
      {/* <IncrementNumber /> */}
      <AutoPlay/>
      {/* <Services/> */}
      {/* <hr/> */}
      <Expertise />
      <hr/>
      {/* <Mentorship /> */}
      {/* <LightColor> */}
      {/* </LightColor> */}
      <CertificationsCarousel certifications={certifications}/>
      <AwardsCarousel awards={awards}/>
      <HomeContactSection/>
      {/* <LightColor>
        <Projects />
      </LightColor> */}

      <Clients />

      {/* 
      <Footer />
      <FooterTwo /> */}

    </Container>
  )
}

export default Home

const Container = styled.div``;
const Banner = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
  height: 100vh;
  @media (max-width: 370px) {
    height: 772px;
    padding-bottom: 2rem;
  }
  @media (min-width:374px) and (max-width: 423px) {
    height: 847px;
    padding-bottom: 2rem;
  }
  @media (min-width:424px) and (max-width: 765px) {
    height: 914px;
    padding-bottom: 2rem;
  }
  @media (min-width:767px) and (max-width: 1022px) {
    height: 480px;
    padding-bottom: 2rem;
  }
  @media (min-width:1023px) and (max-width: 1445px) {
    height: 594px;
    padding-bottom: 2rem;
  }
`;
// background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
const LightColor = styled.div`
background-color:#191923;
`;