import React from 'react'
import Status from './Status'
import experience_back from './../images/Untitled-removebg-preview.webp'
import experience_awais from './../images/Untitled1-removebg-preview.webp'


const EducationsExperience = () => {
    return (
        <div className='mt-5 mb-5'>
            <div className='row'>
                <div className="col-sm-12 col-md-12 col-lg-6 experience-img-container">
                    <div className=''>

                        <img
                            src={experience_back}
                            alt='Awais Ahmad Back'
                            className='experience-img-back'
                        />
                        <img
                            src={experience_awais}
                            alt='Awais Ahmad'
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className='h1 mt-5'>
                        Educations & Experience
                    </div>
                    <div className='ms-2 mt-4 '>
                        <p className='fs-4 section-subheading'>Education</p>
                        <Status title={'MASTER OF SCIENCE IN SOFTWARE ENGINEERING'} subTitle={'FAST NUCES Lahore'} />
                        <Status title={'Bachelors OF SCIENCE IN COMPUTER SCIENCE'} subTitle={'The University of Lahore'} />
                    </div>
                    <div className='ms-2 mt-4 '>
                        <p className='fs-4 section-subheading'>Experience</p>
                        <Status title={'ZAI SYSTEMS (SMC-Private) Limited'} subTitle={'The Founder / CEO'} timePeriod={'Jan 2015 - Present'} />
                        <Status title={'Soloinsight - Cloud Gate Platform'} subTitle={'Technical Project Manager'} timePeriod={'Fab 2023 - Present'} />
                        <Status title={'National Vocational and Technical Training Commission NAVTTC'} subTitle={'Mentor'} timePeriod={'May 2023 - Present'} />
                        <Status title={'EVS Professional Training Institute'} subTitle={'Mentor'} timePeriod={'Aug 2021 - Mar 2023'} />
                        <Status title={'Rodeo Logistics'} subTitle={'Project Manager / Scrum Master'} timePeriod={'May 2022 - Jan 2023'} />
                        <Status title={'SIMPLEX LOGIX'} subTitle={'Senior Software Engineer Project Lead'} timePeriod={'Oct 2020 - April 2022'} />
                        <Status title={'ALCHEMATIVE'} subTitle={'Full Stack Developer / Team lead'} timePeriod={'Jan 2020 – Sep 2020'} />
                        <Status title={'SPORTECH'} subTitle={'Senior Dotnet Developer'} timePeriod={'Jan 2017 – Nov 2019'} />
                        <Status title={'IPSUM SOLUTIONS'} subTitle={'Full Stack Web Developer'} timePeriod={'Jun 2011 - Nov 2016'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EducationsExperience
