import React from 'react'
import { Slide } from "react-awesome-reveal";


const Status = ({ title, subTitle, timePeriod }) => {
    return (
        // <Slide direction="right">
            <div className='mt-1 mb-1'>
                <div className="row">
                    <div className="col-sm-1 col-md-1 col-lg-1">
                        <div className="lollipop-container">
                            <div className="lollipop-boundary">
                                <div className="lollipop-upper"></div>
                            </div>
                            <div className="lollipop-stick">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mentor-data">
                        <p>{title}</p>
                        <p className='subtitle'>{subTitle}</p>
                    </div>
                    <div className="col-sm-5 col-md-5 col-lg-5">
                        <p className='time-frame'>{timePeriod}</p>
                    </div>
                </div>
            </div>
        // </Slide>
    )
}

export default Status
