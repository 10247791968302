import React, {useState} from 'react';
import {FiChevronUp} from "react-icons/fi";
import {ImWhatsapp} from "react-icons/im";
// import Blury from '../Blury';
import '../scroll/ScrollTop.css'

const ScrollTop = () => {
    const [visible, setVisible] = useState(false)
    const [whatsapp] = useState(true)
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
    };
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    };
    window.addEventListener('scroll', toggleVisible);
    const handleWhatsapp = (name, website) => {
        window.open(website);
    }

    return (
        <>
            <div 
            className="rn-back-top" 
            onClick={scrollToTop}
            style={{display: visible ? 'inline' : 'none'}}
            ><FiChevronUp /></div>
            <div 
            className="rn-back-top1" 
            style={{display: whatsapp ? 'inline' : 'none'}}
            >
                <span
                onClick={() => handleWhatsapp( "whatsapp",
                              "https://api.whatsapp.com/send/?phone=923224221287&text&type=phone_number&app_absent=0"
                            )}  
                            >
                    
                 <ImWhatsapp /> 
                     </span>
            </div>
            {/* <Blury /> */}
        </>
    )
}


export default ScrollTop